import { styled } from 'styled-components';

import { useAuth } from 'apps-common/context/auth';
import { t } from 'translations';

import { FooterContainer } from '../../styles/containers';
import { Column, Row } from '../../styles/layout';
import { Paragraph } from '../../styles/text';
import { LogoutButton } from '../LogoutButton';
import { FooterProps } from './types';
import { WebsiteLinks } from './WebsiteLinks';

const LogoutButtonContainer = styled.div`
  margin-left: 20px;
`;

export const DesktopFooter = ({ onLinkClick, showLogout }: FooterProps) => {
  const { logout } = useAuth();
  return (
    <FooterContainer style={{ gap: '20px' }}>
      <Row $wrap="nowrap" style={{ width: '100%', justifyContent: 'space-between' }}>
        <Column style={{ alignItems: 'flex-start', width: '40%' }}>
          <Paragraph $color="white2" $fontSize="small">
            {t('membership_signup_all_rights_reserved')}
          </Paragraph>
          <Paragraph $color="white2" $fontSize="small" $textAlign="left">
            {t('membership_signup_trademarks')}
          </Paragraph>
        </Column>
        <Row style={{ width: '60%' }} $wrap="nowrap">
          <WebsiteLinks onLinkClick={onLinkClick} />
          {showLogout && (
            <LogoutButtonContainer>
              <LogoutButton onClick={() => logout()} />
            </LogoutButtonContainer>
          )}
        </Row>
      </Row>
    </FooterContainer>
  );
};
