import { ReactNode } from 'react';
import { FormattedDate } from 'react-intl';

import { GetSignupResponseRingSerialStatusEnum } from 'oura-account-api-client';
import { styled } from 'styled-components';

import { SubscriptionState } from 'apps-common/types';
import { t } from 'translations';

import { Column, Row } from '../../styles/layout';
import { RingImage } from '../../styles/media';
import { HeaderText, Paragraph, StepIndicator } from '../../styles/text';
import { media } from '../../themes/signup';
import { BackButton } from '../BackButton';
import { AppType, PageType, Step, StyledBaseHeader, StyledRingImageTitleHeaderContainer } from './BaseHeader';
import { MiniBanner } from './MiniBanner';
import { OuraHeaderLogo } from './OuraHeaderLogo';
import { RingPairingNotification } from './RingPairingNotification';

interface MobileHeaderProps {
  appType: AppType;
  pageType: PageType;
  onBackClick?: () => void;
  title?: ReactNode;
  subTitle?: ReactNode;
  steps?: Step;
  error?: string;
  personalRingImage?: React.ReactNode;
  testId?: string;
  createdAt?: string;
  bannerText?: ReactNode;
  ringSerialStatus?: GetSignupResponseRingSerialStatusEnum;
  pendingCancellation?: boolean;
  subscriptionState?: SubscriptionState;
}

const TiltedRingImage = styled(RingImage)`
  position: absolute;
  right: -60px;
  top: 5px;
  width: 200px;
  height: 200px;
  object-fit: contain;

  ${media.medium`
      right: -50px;
      top: -10px;
    `}
`;

export const MobileHeader = ({
  appType,
  pageType,
  onBackClick,
  title,
  subTitle,
  steps,
  testId,
  createdAt,
  bannerText,
  ringSerialStatus,
  pendingCancellation,
  subscriptionState,
}: MobileHeaderProps) => {
  const headerTextSize = appType === 'hub' ? 'large' : 'xxlarge';
  const subTitleTextSize = appType === 'hub' ? 'small' : 'medium';
  const membershipHubText = (
    <Paragraph $fontSize="small" $padding="0 0 0 20px">
      {t('membership_hub_feature_name')}
    </Paragraph>
  );
  const stepsComponent = (
    <StepIndicator style={{ marginBottom: 0 }}>
      {t('membership_signup_step', {
        current_step: steps?.current,
        total_steps: steps?.total,
      })}
    </StepIndicator>
  );
  const headerText = (width: string) => (
    <>
      {bannerText && appType === 'signup' && (
        <MiniBanner marginBottom={'10px'} marginTop={'20px'}>
          {bannerText}
        </MiniBanner>
      )}
      <HeaderText
        $fontSize={headerTextSize}
        style={{
          paddingTop: pageType === 'complete' ? '20px' : '0',
          width: width,
          overflowWrap: 'break-word',
        }}
        data-testid={testId}
      >
        {title}
      </HeaderText>
    </>
  );
  const subTitleText = (
    <Paragraph
      $textAlign="left"
      data-testid="pageSubtitle"
      $fontSize={subTitleTextSize}
      $padding="0"
      $margin="5px 0 0 0"
      $color={pageType === 'create' || pageType === 'hubHome' ? 'grayLightest' : 'white'}
      style={{
        paddingTop: pageType === 'complete' ? '10px' : '0',
        width: pageType === 'complete' ? '90%' : '100%',
      }}
    >
      {subTitle}
    </Paragraph>
  );

  const singUpCompletePageRingImage = (
    <RingImage src="/oura_gold.webp" alt="Ouraring" style={{ alignSelf: 'center' }} />
  );

  const showRingPairingFailureNotification = ringSerialStatus === GetSignupResponseRingSerialStatusEnum.NotReported;
  const ringPairingNotification = showRingPairingFailureNotification && <RingPairingNotification />;

  const getMembershipText = () => {
    if (pendingCancellation) {
      return t('membership_hub_expiring_soon');
    }
    if (subscriptionState === SubscriptionState.MEMBER) {
      return t('membership_hub_member_since', {
        date: <FormattedDate value={createdAt} format="default" />,
      });
    }
    if (subscriptionState === SubscriptionState.EXPIRED) {
      return t('membership_hub_cancelled');
    }
    return null;
  };

  const renderMobileHeader = () => {
    if (pageType === 'landing') {
      return (
        <StyledBaseHeader $alignItems="center" $marginTop="0">
          <RingImage src="/oura_black.webp" alt="Ouraring" $width={'70%'} $margin={'30px'} />
          {/*<RingImage src="/oura-ring.webp" alt="Ouraring" />*/}
          <StyledRingImageTitleHeaderContainer>
            <OuraHeaderLogo width="97px" height="30px" app={appType} />
            {appType === 'hub' && (
              <Paragraph $fontSize="medium" $padding="20px 0 0 0">
                {t('membership_hub_feature_name')}
              </Paragraph>
            )}
            {title && (
              <HeaderText $margin="20px 0 0 0" $fontSize="xxlarge">
                {title}
              </HeaderText>
            )}
            {subTitle && (
              <Paragraph data-testid="pageSubtitle" $fontSize="medium" $padding="20px 0 0 0">
                {subTitle}
              </Paragraph>
            )}
          </StyledRingImageTitleHeaderContainer>
        </StyledBaseHeader>
      );
    } else if (pageType === 'create' || pageType === 'complete') {
      return (
        <StyledBaseHeader
          $marginTop={showRingPairingFailureNotification ? '0' : '20px'}
          $padding={showRingPairingFailureNotification ? '100px 0 0 0' : '0'}
        >
          {appType === 'signup' && ringPairingNotification}
          <Row $justifyContent="space-between" $alignItems="flex-end" $width="100%" $padding="0 0 20px 0">
            <Row $alignItems="flex-end">
              <OuraHeaderLogo height="19px" width="60px" app={appType} />
              {appType === 'hub' && membershipHubText}
            </Row>
            {onBackClick && <BackButton onClick={onBackClick} />}
          </Row>
          {steps && stepsComponent}
          {title && headerText('90%')}
          {subTitle && subTitleText}
          {pageType === 'complete' && singUpCompletePageRingImage}
        </StyledBaseHeader>
      );
    } else if (pageType === 'hubHome') {
      return (
        <StyledBaseHeader $marginTop="30px">
          <Row $width="100%">
            <Row $justifyContent="space-between" $alignItems="flex-end" $width="60%" $padding="0 0 20px 0">
              <Row $alignItems="flex-end">
                <OuraHeaderLogo height="19px" width="60px" app={appType} />
                {membershipHubText}
              </Row>
            </Row>
            <Column $width="100%">
              {headerText('50%')}
              {createdAt ? (
                <Paragraph $margin="0" $fontSize="small">
                  {getMembershipText()}
                </Paragraph>
              ) : null}
            </Column>
            <TiltedRingImage src="/oura_tilted.webp" alt="Ouraring" />
          </Row>
        </StyledBaseHeader>
      );
    } else if (pageType === 'error') {
      return (
        <StyledBaseHeader>
          <Row $alignItems="flex-end">
            <OuraHeaderLogo height="29px" width="93px" app={appType} />
            {appType === 'hub' && (
              <Paragraph $fontSize="small" $padding="0 0 0 20px">
                {t('membership_hub_feature_name')}
              </Paragraph>
            )}
          </Row>
        </StyledBaseHeader>
      );
    }
    return (
      <StyledBaseHeader>
        <OuraHeaderLogo height="29px" width="93px" app={appType} />
      </StyledBaseHeader>
    );
  };

  return renderMobileHeader();
};
