import { useNavigate } from 'react-router';

import { t } from 'translations';
import { Header, HorizontalLine } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { UpdateEmailForm } from '../../components/AccountManagement/UpdateEmailForm';
import { UpdatePasswordForm } from '../../components/AccountManagement/UpdatePasswordForm';
import { routes } from '../../routes';

export const AccountManagePage = () => {
  const navigate = useNavigate();
  const TITLE = t('membership_hub_account_information');
  const SUB_TITLE = t('membership_hub_update_account_information_text');

  return (
    <>
      <Header
        appType="hub"
        pageType="create"
        title={TITLE}
        subTitle={SUB_TITLE}
        onBackClick={() => navigate(routes.index)}
      ></Header>
      <MainContainer $padding="10px 5px 0 5px">
        <UpdatePasswordForm />
        <HorizontalLine $margin="30px 0 40px 0" />
        <UpdateEmailForm />
      </MainContainer>
    </>
  );
};
