import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router';

import { useAuth } from 'apps-common/context/auth';

import { UserAccessCheck, UserAccessCheckProps } from '../components/UserAccessCheck';

// Always checks the user's login state first before further executing codes to prevent calling any endpoints or rendering any components that require token.
export const WithAuthAndAccessCheck = ({ userAccessRights, children }: UserAccessCheckProps) => {
  const navigate = useNavigate();
  const nextRoute = window.location.pathname;
  const { loggedIn } = useAuth();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    // If we have nonce we force new login
    const nonce = searchParams.get('nonce') ?? undefined;
    if (nonce) navigate(`/login?next=${nextRoute}&nonce=${nonce}`);
    else if (!loggedIn) {
      navigate(`/login?next=${nextRoute}`);
    }
  }, [navigate, nextRoute, loggedIn, searchParams]);

  if (!loggedIn) {
    return null;
  }

  return <UserAccessCheck userAccessRights={userAccessRights}>{children}</UserAccessCheck>;
};
