import './styles/normalize.css';

import * as React from 'react';
import * as ReactDOM from 'react-dom/client';

import { StatsigClient } from '@statsig/js-client';
import { StatsigProvider } from '@statsig/react-bindings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from 'styled-components';

import { ConsentManager } from 'apps-common/components/ConsentManager';
import { AuthProvider } from 'apps-common/context/auth';
import { resetAllSlices } from 'apps-common/store';
import { useInitAnalytics } from 'apps-common/utils/analytics';
import { initSentry } from 'apps-common/utils/sentry';
import { LocaleProvider } from 'translations';
import { theme } from 'ui/themes/signup';

import { Router } from './router';
import { GlobalStyles } from './styles/global';

const SS_CLIENT_SDK_KEY = import.meta.env.VITE_SS_CLIENT_SDK_KEY;
const SS_PROXY_API = import.meta.env.VITE_STATSIG_PROXY_API; // bypass adblocker to send events
const ENV = import.meta.env.MODE;

const queryClient = new QueryClient();

const statsigClient = new StatsigClient(
  SS_CLIENT_SDK_KEY,
  {},
  {
    environment: { tier: ENV },
    networkConfig: {
      api: SS_PROXY_API,
    },
  },
);
await statsigClient.initializeAsync();

const Main = () => {
  useInitAnalytics('hub');

  return (
    <React.StrictMode>
      <StatsigProvider client={statsigClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <QueryClientProvider client={queryClient}>
            <AuthProvider
              onLogout={(moiLogoutUrl?: string): void => {
                resetAllSlices();
                sessionStorage.removeItem('paymentMethodId');
                sessionStorage.removeItem('zuoraResponse');
                if (moiLogoutUrl) window.location.replace(moiLogoutUrl);
              }}
            >
              <LocaleProvider>
                <Router />
                <ConsentManager />
              </LocaleProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ThemeProvider>
      </StatsigProvider>
    </React.StrictMode>
  );
};

initSentry();

ReactDOM.createRoot(document.getElementById('root')!).render(<Main />);
