import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { useMutation } from '@tanstack/react-query';
import { emailRegex } from 'common-utils';
import { UpdateEmailRequestBody } from 'oura-account-api-client';
import { styled } from 'styled-components';

import { updateEmail } from 'apps-common/hooks/AccountApi';
import { t } from 'translations';
import { Button, CTALoader, EmailField, ErrorBanner, Form, PasswordField, SubmitButton } from 'ui';
import { Dialog } from 'ui/components/Dialog';
import { StyledEmailIcon } from 'ui/components/Icons';
import { Link, SectionTitle } from 'ui/styles/text';

import { routes } from '../../routes';
import { useStore } from '../../store';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const Title = styled(SectionTitle)`
  margin: 0 10px 20px;
`;

const StyledForm = styled(Form)`
  gap: 0;
  ${Button}[type="submit"] {
    margin-top: 40px;
    margin-bottom: 0;
  }

  & > div:first-child {
    margin-bottom: 8px;
  }
`;

type FormValues = UpdateEmailRequestBody & {
  serverError?: string;
};

export const UpdateEmailForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { clearErrors, formState, handleSubmit, register, setError } = useForm<FormValues>({
    criteriaMode: 'all',
    mode: 'onTouched',
  });
  const [showPassword, setShowPassword] = useState(false);
  const emailDialogRef = useRef<HTMLDialogElement>(null);
  const currentEmail = useStore((state) => state.email);
  const emailMutation = useMutation({
    mutationFn: updateEmail,
  });

  const onClose = () => {
    emailDialogRef.current?.close();
  };

  const emailSuccessDialog = (
    <Dialog
      ref={emailDialogRef}
      onCloseClick={onClose}
      icon={<StyledEmailIcon $width="32px" $height="32px" />}
      title={t('membership_hub_check_your_email')}
      textContents={[t('membership_hub_verify_email')]}
      primaryButton={{
        text: t('membership_hub_return_to_hub_home'),
        onClick: () => navigate(routes.index),
      }}
    />
  );

  const onSubmit = (data: UpdateEmailRequestBody) => {
    emailMutation.mutate(data, {
      onSuccess: () => emailDialogRef.current?.showModal(),
      onError: (error) => {
        const message =
          error.message === 'InvalidPassword'
            ? intl.formatMessage({ id: 'membership_hub_invalid_password' })
            : error.message;
        setError('serverError', { type: 'server', message });
      },
    });
  };

  const TITLE = t('membership_hub_update_email');

  return (
    <Container>
      {formState.errors.serverError && (
        <ErrorBanner marginBottom="8px">{formState.errors.serverError.message}</ErrorBanner>
      )}
      <Title>{TITLE}</Title>

      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        onFocus={() => {
          clearErrors('serverError');
        }}
      >
        <PasswordField
          data-testid="currentPasswordEmailInput"
          showIcon={false}
          showPassword={showPassword}
          onRevealIconClick={() => {
            setShowPassword(!showPassword);
          }}
          placeholder={intl.formatMessage({
            id: 'membership_hub_enter_current_password',
          })}
          {...register('password', {
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'membership_hub_password_required_error_message',
              }),
            },
          })}
          error={formState.errors.password?.message}
        />
        <Link
          $color="helsinkiBlue"
          $fontSize="12px"
          $fontWeight="700"
          $textAlign="right"
          href={routes.requestPasswordReset}
        >
          {t('membership_hub_forgot_password')}
        </Link>
        <EmailField
          data-testid="newEmailInput"
          showIcon={false}
          placeholder={intl.formatMessage({
            id: 'membership_hub_update_email_input',
          })}
          {...register('email', {
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'membership_hub_email_required_error_message',
              }),
            },
            pattern: {
              value: emailRegex,
              message: intl.formatMessage({
                id: 'membership_error_invalid_email',
              }),
            },
            validate: (value) =>
              value !== currentEmail ||
              intl.formatMessage({
                id: 'membership_error_new_email_validation',
              }),
          })}
          error={formState.errors.email?.message}
        />
        <SubmitButton disabled={!formState.isValid || emailMutation.isPending}>
          {emailMutation.isPending ? <CTALoader /> : TITLE}
        </SubmitButton>
      </StyledForm>
      {emailSuccessDialog}
    </Container>
  );
};
