import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import {
  type AddressSlice,
  createAddressSlice,
  createHubSlice,
  createMemberSlice,
  createPaymentErrorSlice,
  createProductSlice,
  type HubSlice,
  type MemberSlice,
  type PaymentErrorSlice,
  type ProductSlice,
} from 'apps-common/store';

export type HubStore = AddressSlice & HubSlice & MemberSlice & PaymentErrorSlice & ProductSlice;

export const useStore = create<HubStore>()(
  persist(
    (...a): HubStore => ({
      ...createAddressSlice(...a),
      ...createMemberSlice(...a),
      ...createPaymentErrorSlice(...a),
      ...createProductSlice(...a),
      ...createHubSlice(...a),
    }),
    { name: 'hub', storage: createJSONStorage(() => sessionStorage) },
  ),
);
