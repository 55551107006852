import { forwardRef, PropsWithChildren, ReactNode } from 'react';

import { styled } from 'styled-components';

import { RadioButton, RadioButtonProps } from './RadioButton';

const Background = styled.div(
  ({ theme }) => `
    background-color: ${theme.colors.grayDarkest};
    border-radius: 12px;
  `,
);

const LabelRow = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  input[type='radio'] {
    flex-shrink: 0;
  }
`;

const Group = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`;

const OptionContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-bottom: 1px solid ${theme.colors.grayDark};

    /* Remove border clipping background on last element. Keep same as bg border-radius */
    &:last-child {
      border-radius: 0 0 12px 12px;
    }
`,
);

export interface RadioGroupOptionProps extends RadioButtonProps, PropsWithChildren {
  labelText: ReactNode;
}

export const RadioGroupOption = forwardRef<HTMLInputElement, RadioGroupOptionProps>(
  ({ labelText, children, ...radioProps }, ref) => (
    <OptionContainer>
      <LabelRow>
        {labelText}
        <RadioButton ref={ref} {...radioProps} />
      </LabelRow>
      {children}
    </OptionContainer>
  ),
);

type RadioGroupProps = React.PropsWithChildren;

export const RadioGroup = forwardRef<HTMLFieldSetElement, RadioGroupProps>(({ children }, ref) => (
  <Background>
    {/* eslint-disable-next-line styled-components-a11y/no-noninteractive-element-to-interactive-role */}
    <Group role="radiogroup" ref={ref}>
      {children}
    </Group>
  </Background>
));
