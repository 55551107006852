import { css, type DefaultTheme } from 'styled-components';

const colors = {
  helsinkiBlue: '#A2D3E8',
  white: '#FFFFFF',
  white2: '#F2F3F5',
  background: '#000000',
  grayMedium: '#32353B',
  grayLightest: '#9FA7B8',
  grayLight: '#72757C',
  grayDarkest: '#151619',
  grayNeutral: 'rgba(189, 189, 189, 1)',
  blueLight: '#6FCAFF',
  blueDarkest: '#0D1F3B',
  switchOverlay: 'rgba(33, 33, 33, 0.08)',
  grayscale4: '#CECAC4',
  lightGreen: '#55DC83',
  lightRed: '#FF3B30',
  errorBackground: '#1B2F34',
  errorText: '#FF3B30',
  bannerErrorState: '#FC6558',
  transparent: 'rgba(0, 0, 0, 0)',
  disabledBackground: 'rgba(50, 53, 59, 50%)',
  grayDark: '#222428',
} as const;

const fonts = 'Akkurat LL';

const fontSizes = {
  xxsmall: '10px', // Footer paragraphs etc.
  xsmall: '12px', // used by some SmallText with $fontSize prop
  small: '14px', // used in SmallText
  medium: '16px', // h3 and p size
  large: '20px', // h1 size on mobile, otherwise found only on hub landing in mobile (h2)
  xlarge: '22px', // Medium title (Feature Highlights...), same size always
  xxlarge: '32px', // h1: 32px on desktop, otherwise used by a single h2 on hub landing page
  xxxlarge: '34px', // Pop up component header
  xxxxlarge: '60px', // Desktop header text
} as const;

const margin = '24px'; //New designs seem to align with 24px margins also between most of the elements

export const breakpoints = {
  xsmall: '430px',
  small: '576px',
  medium: '768px', // ipad
  large: '992px', // laptop < 12" & ipad side
  xlarge: '1200px', // laptop > 12" & desktop
} as const;

// https://styled-components.com/docs/api#typescript
declare module 'styled-components' {
  export interface DefaultTheme {
    breakpoints: typeof breakpoints;
    colors: typeof colors;
    fonts: typeof fonts;
    fontSizes: typeof fontSizes;
    margin: typeof margin;
  }
}

export const theme: DefaultTheme = {
  breakpoints,
  colors,
  fonts,
  fontSizes,
  margin,
};

//General breakpoint mechanism
type Breakpoint = keyof DefaultTheme['breakpoints'];

export const media = (Object.keys(breakpoints) as Breakpoint[]).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args: Parameters<typeof css>) => css`
      /* stylelint-disable-next-line media-query-no-invalid */
      @media (min-width: ${breakpoints[label]}) {
        ${css(...args)}
      }
    `;
    return accumulator;
  },
  {} as Record<Breakpoint, typeof css>,
);
