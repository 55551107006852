import { renewSubscription } from 'apps-common/graphql/renewSubscription';
import { updateAccount } from 'apps-common/graphql/updateAccount';
import { PaymentMethodType } from 'apps-common/types';
import { convertPaymentMethodTypeToTrackingType, track } from 'apps-common/utils/analytics';
import { isStrictNever } from 'apps-common/utils/isStrictNever';
import { logger } from 'apps-common/utils/logger';
import { isGatewayError } from 'apps-common/utils/SupaError';
import { isSupaGQLError } from 'apps-common/utils/SupaGraphQLError';
import { getLocale } from 'translations';

import { routes } from '../routes';
import { useStore } from '../store';

export const onCallbackError = (type: PaymentMethodType) => (error: Error) => {
  const setPaymentError = useStore.getState().setPaymentError;
  setPaymentError(error);

  track({
    event: 'Updating Payment Method Failed',
    payload: {
      paymentMethodType: convertPaymentMethodTypeToTrackingType(type),
      reason: error.message ?? 'Unknown error',
    },
  });

  logger.error('Error updating payment method', { error });
  window.parent.location.replace(routes.updatePaymentMethod);
};

const logGatewayError = (type: PaymentMethodType) => (error: unknown) => {
  if ((isSupaGQLError(error) && error.code === 'PAYMENT_GATEWAY') || isGatewayError(error)) {
    const { message, code } = error;
    logger.error(`GatewayError in ${type} callback`, {
      message,
      code,
    });
  }

  throw error;
};

export const onCallbackSuccess = (type: PaymentMethodType) => {
  let getGateway: () => string;
  switch (type) {
    case PaymentMethodType.CreditCard: {
      getGateway = () => useStore.getState().gateways.creditCardGatewayName;
      break;
    }
    case PaymentMethodType.PayPal: {
      getGateway = () => useStore.getState().gateways.paypalGatewayName;
      break;
    }
    default: {
      isStrictNever(type);
    }
  }

  const gatewayErrorLogHandler = logGatewayError(type);
  const criticalErrorHandler = (error: unknown) => {
    if ((isSupaGQLError(error) && error.code === 'PAYMENT_GATEWAY') || isGatewayError(error)) {
      onCallbackError(type)(error);
      return;
    }

    logger.error(`Error in ${type} callback`, { error });
    window.parent.location.replace(routes.error);
    return;
  };

  return async (paymentMethodId: string, flow?: string) => {
    const email = useStore.getState().email;
    const { currency, billingPeriod } = useStore.getState().selectedRatePlan!;
    const userAddressForm = useStore.getState().userAddressForm!;
    const gatewayName = getGateway();
    const locale = getLocale();

    logger.debug(`${type} callback updating account`);
    try {
      await updateAccount(paymentMethodId, gatewayName, userAddressForm, email, currency);
    } catch (error) {
      return criticalErrorHandler(error);
    }

    logger.debug(`${type} callback updating account successful`);

    const trackingData = {
      paymentMethodType: convertPaymentMethodTypeToTrackingType(type),
      currency,
      locale,
      billingPeriod,
      paymentGateway: gatewayName,
    };

    track({
      event: 'Payment Method Updated',
      payload: {
        ...trackingData,
      },
    });

    if (flow === 'renew') {
      logger.debug(`${type} callback renewing subscription`, {
        flow,
      });
      try {
        await renewSubscription(billingPeriod).catch(gatewayErrorLogHandler);
      } catch (error) {
        return criticalErrorHandler(error);
      }

      logger.debug(`${type} callback renew successful`);

      track({
        event: 'Subscription Renewed',
        payload: {
          ...trackingData,
        },
      });
    }

    logger.info(`${type} callback success, redirecting to success page`, {
      flow,
    });
    window.parent.location.replace(
      flow === 'renew' ? routes.updatePaymentMethodSuccessRenew : routes.updatePaymentMethodSuccess,
    );
  };
};
