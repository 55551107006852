import { useState } from 'react';
import { useIntl } from 'react-intl';

import { track } from 'apps-common/utils/analytics';
import { t } from 'translations';
import { Paragraph } from 'ui/styles/text';

import { NavigationRow, SectionCard } from '../SectionCard';
import { CancellationModal } from './CancellationModal';

interface CancelSubscriptionProps {
  nextBillingDate?: string;
  refetchAccountData: () => void;
}

export const CancelSubscription = ({ nextBillingDate, refetchAccountData }: CancelSubscriptionProps) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <>
      {showModal && (
        <CancellationModal
          showModal={showModal}
          setShowModal={setShowModal}
          nextBillingDate={nextBillingDate}
          refetchAccountData={refetchAccountData}
        />
      )}
      <SectionCard>
        <NavigationRow
          label={intl.formatMessage({ id: 'membership_hub_cancel_membership' })}
          color="lightRed"
          onClick={() => {
            openModal();
            track({
              event: 'Link Clicked',
              payload: {
                cta: 'cancel_membership',
                location: 'body',
              },
            });
          }}
        />
      </SectionCard>

      <Paragraph $fontSize="xsmall" $color="grayLight" $padding="10px 20px 30px">
        {t('membership_hub_cancel_membership_disclaimer')}
      </Paragraph>
    </>
  );
};
