import { useEffect } from 'react';

import { styled } from 'styled-components';

import { t } from 'translations';
import { Paragraph } from 'ui/styles/text';

import { initPaypal } from '../../utils/paypal';

const StyledButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: unset;
  border: unset;
  padding: 0;
`;

const StyledPayPalContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
`;

export interface PayPalFormProps {
  id: string;
  onStart: React.MouseEventHandler<HTMLButtonElement>;
  paypalMerchantId: string;
}

export const PayPalForm = ({ id, onStart, paypalMerchantId }: PayPalFormProps) => {
  useEffect(() => {
    initPaypal(id, paypalMerchantId);
  }, [id, paypalMerchantId]);
  return (
    <StyledPayPalContainer>
      <Paragraph $fontSize="medium" $lineHeight="140%">
        {t('membership_signup_paypal_checkout_text')}
      </Paragraph>
      <StyledButton className="mx-auto" id={id} onClick={onStart} />
    </StyledPayPalContainer>
  );
};
