import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { zipCodeRegex } from 'common-utils';
import { styled } from 'styled-components';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import { t } from 'translations';
import { DropDown, DropdownOption, IconButton, TextField } from 'ui';
import { StyledMinusIcon, StyledPlusIcon } from 'ui/components/Icons';
import { Popover, PopoverContainer } from 'ui/components/Popover';
import { StyledClickToShowInputButton } from 'ui/styles/forms';
import { SmallText } from 'ui/styles/text';
import { media } from 'ui/themes/signup';

import type { CountryWeSellTo } from '../../types';
import type { FormValues } from './types';

import { sortByLabel, streetAddressContainsRestrictedCharacters } from '../../utils/address';
import { removeSpaces } from '../../utils/helpers';

const FlexWrap = styled.div`
  display: flex;
  flex-direction: column;
  ${media.medium`
    flex-direction: row;
  `}
  gap: 10px;
`;

interface AddressInputsProps {
  addressType: 'billingAddress' | 'shippingAddress';
  countries: CountryWeSellTo[];
  regions: CountryWeSellTo['regions'];
  allowCountryChange: boolean;
}

export const AddressInputs = ({ addressType, countries, regions, allowCountryChange }: AddressInputsProps) => {
  const intl = useIntl();
  const [showPopover, setShowPopover] = useState(false);

  const formalizedCountryData: DropdownOption[] = sortByLabel(
    countries.map(({ countryCode, name }) => ({
      value: countryCode,
      label: intl.formatMessage({ id: `country_${countryCode}` }) ?? name,
    })),
  );

  const {
    formState: { errors },
    getValues,
    register,
    resetField,
    setValue,
    unregister,
  } = useFormContext<FormValues>();

  const showSecondAddressField = !isNil(getValues(`${addressType}.address2`));
  const displayState = !isEmpty(regions);

  const lokalisedStateList: DropdownOption[] = sortByLabel(
    regions.map(({ value, label }) => {
      const noSpaceStateCode = removeSpaces(value);
      return {
        value: value,
        label:
          intl.formatMessage({
            id: `state_${getValues(`${addressType}.country`)}_${noSpaceStateCode}`,
          }) ?? label,
      };
    }),
  );

  return (
    <>
      <TextField
        id={`${addressType}.firstName`}
        placeholder={intl.formatMessage({
          id: 'membership_signup_billing_address_first_name_field',
        })}
        placeholderAsLabel
        error={errors[addressType]?.firstName?.message}
        {...register(`${addressType}.firstName`, {
          required: intl.formatMessage({
            id: 'membership_addressform_error_firstname_missing',
          }),
          maxLength: {
            value: 100,
            message: intl.formatMessage({
              id: 'membership_addressform_error_too_long',
            }),
          },
        })}
      />
      <TextField
        id={`${addressType}.lastName`}
        placeholder={intl.formatMessage({
          id: 'membership_signup_billing_address_last_name_field',
        })}
        placeholderAsLabel
        error={errors[addressType]?.lastName?.message}
        {...register(`${addressType}.lastName`, {
          required: intl.formatMessage({
            id: 'membership_addressform_error_lastname_missing',
          }),
          maxLength: {
            value: 100,
            message: intl.formatMessage({
              id: 'membership_addressform_error_too_long',
            }),
          },
        })}
      />
      <TextField
        id={`${addressType}.address1`}
        placeholder={intl.formatMessage({
          id: 'membership_signup_billing_address_address_field',
        })}
        placeholderAsLabel
        error={errors[addressType]?.address1?.message}
        {...register(`${addressType}.address1`, {
          required: intl.formatMessage({
            id: 'membership_addressform_error_address1_missing',
          }),
          maxLength: {
            value: 255,
            message: intl.formatMessage({
              id: 'membership_addressform_error_too_long',
            }),
          },
          validate: (value) =>
            !streetAddressContainsRestrictedCharacters(value) ||
            intl.formatMessage({
              id: 'membership_address_contains_restricted_characters',
            }),
        })}
      />

      {showSecondAddressField && (
        <TextField
          id={`${addressType}.address2`}
          placeholder={intl.formatMessage({
            id: 'membership_signup_billing_address_address2_field',
          })}
          placeholderAsLabel
          error={errors[addressType]?.address2?.message}
          {...register(`${addressType}.address2`, {
            maxLength: {
              value: 255,
              message: intl.formatMessage({
                id: 'membership_addressform_error_too_long',
              }),
            },
          })}
        >
          <IconButton
            name="close"
            type="button"
            onClick={() => unregister(`${addressType}.address2`)}
            icon={<StyledMinusIcon />}
          />
        </TextField>
      )}
      {!showSecondAddressField && (
        <StyledClickToShowInputButton type="button" onClick={() => setValue(`${addressType}.address2`, '')}>
          <StyledPlusIcon />
          <SmallText style={{ paddingLeft: '10px', margin: 0 }}>
            {t('membership_signup_billing_address_billing_apt')}
          </SmallText>
        </StyledClickToShowInputButton>
      )}

      <FlexWrap>
        <TextField
          id={`${addressType}.city`}
          placeholder={intl.formatMessage({
            id: 'membership_signup_billing_address_city_field',
          })}
          placeholderAsLabel
          error={errors[addressType]?.city?.message}
          {...register(`${addressType}.city`, {
            required: intl.formatMessage({
              id: 'membership_addressform_error_city_missing',
            }),
            maxLength: {
              value: 40,
              message: intl.formatMessage({
                id: 'membership_addressform_error_too_long',
              }),
            },
          })}
        />
        {displayState && (
          <DropDown
            id={`${addressType}.state`}
            label={intl.formatMessage({
              id: 'membership_signup_billing_address_billing_state',
            })}
            firstOptionEmpty
            value={getValues(`${addressType}.state`)}
            options={lokalisedStateList}
            error={errors[addressType]?.state?.message}
            {...register(`${addressType}.state`, {
              required: intl.formatMessage({
                id: 'membership_addressform_error_state_missing',
              }),
              shouldUnregister: true,
            })}
          />
        )}
      </FlexWrap>

      <FlexWrap>
        <PopoverContainer setShowPopover={setShowPopover} isOpen={showPopover} popoverEnabled={!allowCountryChange}>
          <DropDown
            id={`${addressType}.country`}
            data-testid={`${addressType}.country`}
            label={
              allowCountryChange
                ? intl.formatMessage({
                    id: 'membership_signup_billing_address_country_field',
                  })
                : intl.formatMessage({
                    id: 'membership_address_country_disabled_field',
                  })
            }
            firstOptionEmpty
            value={getValues(`${addressType}.country`)}
            options={formalizedCountryData}
            error={errors[addressType]?.country?.message}
            {...register(`${addressType}.country`, {
              onChange: () => {
                resetField(`${addressType}.state`);
              },
              required: {
                value: allowCountryChange,
                message: intl.formatMessage({
                  id: 'membership_addressform_error_country_missing',
                }),
              },
            })}
            disabled={!allowCountryChange}
          />
          <Popover
            isOpen={!allowCountryChange && showPopover}
            content={t('membership_hub_address_country_field_disabled_reason')}
          />
        </PopoverContainer>

        <TextField
          id={`${addressType}.postalCode`}
          placeholder={intl.formatMessage({
            id: 'membership_signup_billing_address_billing_zip',
          })}
          placeholderAsLabel
          error={errors[addressType]?.postalCode?.message}
          {...register(`${addressType}.postalCode`, {
            required: {
              value: true,
              message: intl.formatMessage({
                id: 'membership_addressform_error_zipcode_missing',
              }),
            },
            maxLength: {
              value: 10,
              message: intl.formatMessage({
                id: 'membership_addressform_error_too_long',
              }),
            },
            pattern: {
              value: zipCodeRegex,
              message: intl.formatMessage({
                id: 'membership_addressform_error_zipcode_invalid',
              }),
            },
          })}
        />
      </FlexWrap>
    </>
  );
};
