import { useEffect, useRef, useState } from 'react';
import { FormattedDate } from 'react-intl';

import { cancelSubscription } from 'apps-common/graphql/cancelSubscription';
import { track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { logger } from 'apps-common/utils/logger';
import { t } from 'translations';
import { Dialog } from 'ui/components/Dialog';
import { StyledMembershipLogoIcon } from 'ui/components/Icons';
import { Link } from 'ui/styles/text';

interface CancellationModalProps {
  nextBillingDate?: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  refetchAccountData: () => void;
}

export const CancellationModal = ({
  nextBillingDate,
  showModal,
  setShowModal,
  refetchAccountData,
}: CancellationModalProps) => {
  const ref = useRef<HTMLDialogElement>(null);

  const [cancelInProgress, setCancelInProgress] = useState(false);

  if (!nextBillingDate) {
    throwError('hubGeneralError', 'nextBillingDate is required to cancel');
  }

  useEffect(() => {
    if (showModal) {
      ref.current?.showModal();
    }
  }, [showModal]);

  const onClose = () => {
    ref.current?.close();
    setShowModal(false);
    track({
      event: 'CTA Clicked',
      payload: { cta: 'keep_my_membership', action: 'cancel_membership' },
    });
  };

  const onConfirm = async () => {
    setCancelInProgress(true);
    const res = await cancelSubscription();
    logger.debug('CancelSubscription response', { res });
    ref.current?.close();
    setCancelInProgress(false);
    setShowModal(false);

    track({
      event: 'CTA Clicked',
      payload: { cta: 'cancel_my_membership', action: 'cancel_membership' },
    });
    refetchAccountData();
  };

  const TITLE = t('membership_hub_cancel_dialog_title');
  const FIRST_TEXT = t('membership_hub_cancellation_modal_body_text_1');
  const SECOND_TEXT = t('membership_hub_cancellation_modal_body_text_2');
  const FINE_PRINT = [
    t('membership_hub_cancel_dialog_fine_print', {
      expiracy_date: <FormattedDate value={nextBillingDate} format="default" />,
      link: (
        <Link
          key="link"
          color={'grayLight'}
          $fontSize={'xsmall'}
          href={'https://support.ouraring.com/hc/en-us/articles/4409086524819-Oura-Membership-FAQs'}
          target="_blank"
        >
          {t('membership_hub_cancel_dialog_learn_more')}
        </Link>
      ),
    }),
  ];
  return (
    <Dialog
      ref={ref}
      onCloseClick={onClose}
      icon={<StyledMembershipLogoIcon />}
      title={TITLE}
      textContents={[FIRST_TEXT, SECOND_TEXT]}
      finePrint={FINE_PRINT}
      primaryButton={{
        text: t('membership_hub_cancel_dialog_keep_my_membership'),
        onClick: onClose,
      }}
      secondaryButton={{
        text: t('membership_hub_cancel_dialog_cancel_membership'),
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: onConfirm,
        disabled: cancelInProgress,
      }}
    ></Dialog>
  );
};
