import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { PaymentMethodForm } from 'apps-common/components/PaymentMethodForm';
import { useGetAccount } from 'apps-common/hooks/useGetAccount';
import { useGetPaymentGateways } from 'apps-common/hooks/useGetPaymentGateways';
import { Currency, FlowType } from 'apps-common/types';
import { track } from 'apps-common/utils/analytics';
import { throwError } from 'apps-common/utils/errorHandler';
import { logger } from 'apps-common/utils/logger';
import { renderPaymentErrorMessage } from 'apps-common/utils/paymentError';
import { onPaypalStart } from 'apps-common/utils/paypal';
import { periodToHubPaymentMethodSubtitleWithPriceKey } from 'apps-common/utils/productLocalization';
import { t } from 'translations';
import { ErrorBanner, Header, Loader } from 'ui';
import { MainContainer } from 'ui/styles/containers';

import { routes } from '../routes';
import { useStore } from '../store';
import { ableToSelectPlan, getFlowType } from '../utils/member';

const APP_URL = import.meta.env.VITE_URL;
const PAYPAL_COMPONENT_ID = 'paypal-button';
const PAYPAL_MERCHANT_ID_US = import.meta.env.VITE_PAYPAL_MERCHANT_ID_US;
const PAYPAL_MERCHANT_ID_ROW = import.meta.env.VITE_PAYPAL_MERCHANT_ID_ROW;

const usePrepoulateAddress = () => {
  const { billingAddress } = useStore((state) => state.userAddressForm!);
  const isoAlpha3Code = useStore((state) => state.billingCountryIsoAlpha3Code);

  return {
    creditCardCountry: isoAlpha3Code,
    creditCardState: billingAddress?.state,
    creditCardAddress1: billingAddress?.address1,
    creditCardAddress2: billingAddress?.address2,
    creditCardCity: billingAddress?.city,
    creditCardPostalCode: billingAddress?.postalCode,
  };
};

const useSaveGatewaysToStore = (currency: Currency) => {
  const country = useStore((state) => state.userAddressForm!.shippingAddress.country);
  const setGateways = useStore((state) => state.setGateways);

  const { data: gatewayData, error } = useGetPaymentGateways(country, currency);
  useEffect(() => {
    if (gatewayData) {
      setGateways(gatewayData);
    }
  }, [gatewayData, setGateways]);

  if (error) {
    logger.error('Fetching payment gateways failed', {
      country,
      currency,
      gatewayData,
      error,
    });
    throwError('hubGeneralError', error);
  }
};

export const PaymentMethodUpdatePage = () => {
  const navigate = useNavigate();
  const existingAddressInUse = useStore((state) => state.existingAddressInUse);

  const clearPaymentError = useStore((state) => state.clearPaymentError);
  const setPaymentError = useStore((state) => state.setPaymentError);
  const paymentError = useStore((state) => state.paymentError);
  const userAddressForm = useStore((state) => state.userAddressForm);
  const ratePlan = useStore((state) => state.selectedRatePlan);

  const { data, error: errorInGetAccount } = useGetAccount();

  const populatedFields = usePrepoulateAddress();

  if (errorInGetAccount || !data) {
    throwError('errorOnGetAccount', errorInGetAccount);
  }
  const { currentSubscription } = data.account;

  if (!currentSubscription) {
    throwError('noSubscriptionFound');
  }
  if (!userAddressForm) {
    throwError('noUserAddressFormFound');
  }

  // always use user's ratePlan currency
  const { currency, amount, billingPeriod } = ratePlan!;
  useSaveGatewaysToStore(currency as Currency);
  const {
    hostedPageId,
    creditCardGatewayName: ccGatewayName,
    paypalGatewayName: paypalGatewayName,
  } = useStore((state) => state.gateways);

  const flowType = getFlowType(currentSubscription);
  const { shippingAddress } = userAddressForm;
  const previousPage = ableToSelectPlan(currentSubscription)
    ? routes.selectPlanForRenew
    : existingAddressInUse
      ? routes.SelectAddressUsage
      : routes.addressForm;

  const subPrice = `${amount.toLocaleString(undefined, {
    style: 'currency',
    currency: currency,
  })} ${currency}`;
  const subTitle = t(periodToHubPaymentMethodSubtitleWithPriceKey(billingPeriod), {
    subscription_price: subPrice,
  });

  const returnUrl = new URL(`${routes.paypalCallback}${flowType === FlowType.renew ? '?renew=true' : ''}`, APP_URL)
    .href;
  const cancelUrl = new URL(routes.updatePaymentMethod, APP_URL).href;

  const isUnitedStates = shippingAddress.country === 'US';
  const paypalMerchantId: string = isUnitedStates ? PAYPAL_MERCHANT_ID_US : PAYPAL_MERCHANT_ID_ROW;

  return (
    <>
      <Header
        appType="hub"
        pageType="create"
        title={t('membership_signup_payment_method_title')}
        subTitle={subTitle}
        onBackClick={() => navigate(previousPage)}
      />
      <MainContainer>
        {!flowType && <Loader />}
        {paymentError && <ErrorBanner marginBottom="15px">{renderPaymentErrorMessage(paymentError)}</ErrorBanner>}
        {flowType && (
          <PaymentMethodForm
            flowType={flowType}
            hsaEligible={isUnitedStates}
            creditCard={{
              pageId: hostedPageId,
              gatewayName: ccGatewayName,
              prepopulatedFields: populatedFields,
              onSubmit: () => {
                clearPaymentError();
                logger.info('Submitting credit card');
                if (flowType === FlowType.normal) {
                  track({
                    event: 'CTA Clicked',
                    payload: { cta: 'save', action: 'payment_method_updated' },
                  });
                } else if (flowType === FlowType.renew) {
                  track({
                    event: 'CTA Clicked',
                    payload: {
                      cta: 'next',
                      action: 'renew_next_step',
                      step: 'payment_method',
                    },
                  });
                }
              },
              clientErrorCallback: (zClientError) => {
                const { errorCode: code, errorMessage: message, ...rest } = zClientError;
                setPaymentError({ ...rest, message, code: code.toString() });
                logger.warn('CreditCardForm client error', {
                  error: zClientError,
                });
              },
            }}
            paypal={{
              gatewayName: paypalGatewayName,
              componentId: PAYPAL_COMPONENT_ID,
              merchantId: paypalMerchantId,
              onSubmit: () => {
                clearPaymentError();
                logger.info('Submitting Paypal');
                if (flowType === FlowType.normal) {
                  track({
                    event: 'CTA Clicked',
                    payload: { cta: 'save', action: 'payment_method_updated' },
                  });
                } else if (flowType === FlowType.renew) {
                  track({
                    event: 'CTA Clicked',
                    payload: {
                      cta: 'next',
                      action: 'renew_next_step',
                      step: 'payment_method',
                    },
                  });
                }

                void onPaypalStart({
                  returnUrl,
                  cancelUrl,
                  isUnitedStates,
                  currency,
                });
              },
            }}
          />
        )}
      </MainContainer>
    </>
  );
};
